/* Card.css */
.card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s;
  }

  .card:hover {
    transform: scale(1.05); /* Slight zoom on hover */
  }

  .card-image {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .card-content {
    padding: 16px;
  }

  .card-title {
    margin: 0;
    color: #3B2B52;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .card-description {
    color: #494949;
    font-size: 11px;
    font-family: poppins;
    font-weight: 500;
    text-align: center;
  }
