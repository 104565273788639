/* HeroSection.css */
.input-button-container {
  background-color: white; /* Set the background color to white */
  padding: 5px 10px; /* Add some padding around the input and button */
  display: flex; /* Use flexbox to align items horizontally */
  align-items: center; /* Center items vertically */
  border-radius: 10px; /* Optional: Add rounded corners */
 
  width: 100%; /* Optional: Set a maximum width */
}

.custom-input, .custom-button {
  height: 40px; /* Set a fixed height for both input and button */
  line-height: 40px; /* Align text vertically */
  padding: 0 20px; /* Add horizontal padding */
}

.custom-input {
  width: 100%; /* Make the input take the full width of its container */
  max-width: 600px; /* Set a maximum width to ensure it doesn't get too wide */
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
  background-color: white; /* Set the input box color to white */
  border: 1px solid #ccc; /* Optional: Add a border to match the button's style */
  padding: 0 10px; /* Add padding for better text alignment */
  font-size: 0.9rem; /* Adjust font size to make text smaller */
}

.email-input {
  height: 40px; /* Ensure the height matches the button */
  background-color: white; /* Ensure the input box is white */
  border: none; /* Remove any default border */
  outline: none; /* Remove default outline */
  padding: 0 15px; /* Add padding for better text alignment */
}

/* General styling for the section */
.section-intro {
    /* background-color: #f8f9fa; Optional: Light background color */
    padding: 2rem 0; /* Optional: Vertical padding */
  }
  
  .text-gradient {
    background: linear-gradient(90deg, rgba(255,0,150,1) 0%, rgba(0,204,255,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  /* Responsive adjustments */
  
  /* Mobile view (up to 576px) */
  @media (max-width: 576px) {
    .display-4 {
      font-size: 2.5rem; /* Adjust font size */
    }
  
    .lead {
      font-size: 1rem; /* Adjust font size */
    }
  
    .btn-primary-hero {
      font-size: 0.9rem; /* Adjust button font size */
      padding: 0.75rem 1.25rem; /* Adjust button padding */
    }
  
    .img-fluid {
      max-height: 80px; /* Adjust image height */
    }
  }
  
  /* Tablet view (577px to 992px) */
  @media (min-width: 577px) and (max-width: 992px) {
    .display-4 {
      font-size: 3rem; /* Adjust font size */
    }
  
    .lead {
      font-size: 1.25rem; /* Adjust font size */
    }
  
    .btn-primary-hero {
      font-size: 1rem; /* Adjust button font size */
      padding: 0.875rem 1.5rem; /* Adjust button padding */
    }
  
    .img-fluid {
      max-height: 90px; /* Adjust image height */
    }
  }
  
  /* Desktop view (993px and up) */
  @media (min-width: 993px) {
    .display-4 {
      font-size: 4rem; /* Adjust font size */
    }
  
    .lead {
      font-size: 1.5rem; /* Adjust font size */
    }
  
    .btn-primary-hero {
      font-size: 1.1rem; /* Adjust button font size */
      padding: 1rem 1.75rem; /* Adjust button padding */
    }
  
    .img-fluid {
      max-height: 100px; /* Adjust image height */
    }
  }
  
.email-signup-form {
  width: 100%; /* Make the form take the full width of its container */
  max-width: 415px; /* Set a maximum width to ensure it doesn't get too wide */
  margin: 0 auto; /* Center the form horizontally */
}
  
/* Ensure the container is centered */
.container-3 {

  text-align: center; /* Center text inside the container */
}

/* Ensure the heading is centered */
.heading-2 {
  text-align: center; /* Center the text */
}
  