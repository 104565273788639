.container {
    font-family: Arial, sans-serif;
  }

  .cards {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }

  .card {
    flex: 1;
    padding: 20px;
    color: white;
    border-radius: 10px;
    background-size: cover;
  }

  h2 {
    margin-bottom: 10px;
  }

  .view-articles {
    background-color: #6a5acd;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
